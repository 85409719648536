import ajax from './ajax';

export function getTranslations(prefix, cached = null) {
  return new Promise((resolve, reject) => {
    if (cached) {
      // use cached data
      return resolve(cached);
    }
    ajax({
      method: 'get',
      url: `/api/translations/${prefix}`,
    })
    .then(response => {
      cached = parseTranslations(response.data);
      resolve(cached);
    })
    .catch(err => {
      reject(err);
    });
  });
}

function parseTranslations(translations) {
  let result = null;
  if (translations && translations.length > 0) {
    result = {};
    translations.forEach(t => {
      result[t.alias] = t.content;
    });
  }
  return result;
}
