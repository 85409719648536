const ajax = CMS.axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  transitional: {
    silentJSONParsing: false,
  },
  responseType: 'json',
  transformResponse: [
    (data) => {
      // update CSRF tokens
      const res = JSON.parse(data) || null;
      if (typeof res === 'object' && res && typeof res.csrf_token !== 'undefined') {
        Array.from(document.querySelectorAll('input[name="csrf_token"]')).forEach(inputEl => {
          inputEl.value = res.csrf_token;
        });
      }
      return JSON.parse(data) || data;
    },
  ],
});

export default ajax;
