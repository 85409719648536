class Quiz {
  
  constructor(quizEl, options = {}) {
    
    this.settings = Object.assign({
      autoInit: true,
      hiddenClassname: 'd-none',
      panelWelcome: '[data-quiz-panel-welcome]',
      panelResult: '[data-quiz-panel-result]',
      resultContent: '[data-quiz-result]',
      errorContent: '[data-quiz-error]',
      panelQuestions: '[data-quiz-panel-questions]',
      quizPanel: '[data-quiz-panel]',
      panelNumAttribute: 'data-quiz-panel',
      startBtn: '[data-quiz-start]',
      restartBtn: '[data-quiz-restart]',
      nextBtn: '[data-quiz-step="next"]',
      prevBtn: '[data-quiz-step="prev"]',
      submit: null,
    }, options);

    this.quizEl = (typeof quizEl === 'string') ? document.querySelector(quizEl) : quizEl;

    this.panelWelcome = (typeof this.settings.panelWelcome === 'string') ? this.quizEl.querySelector(this.settings.panelWelcome) : this.settings.panelWelcome;
    this.panelResult = (typeof this.settings.panelResult === 'string') ? this.quizEl.querySelector(this.settings.panelResult) : this.settings.panelResult;
    this.panelQuestions = (typeof this.settings.panelQuestions === 'string') ? this.quizEl.querySelector(this.settings.panelQuestions) : this.settings.panelQuestions;
    this.startBtn = (typeof this.settings.startBtn === 'string') ? this.quizEl.querySelector(this.settings.startBtn) : this.settings.startBtn;
    this.restartBtn = (typeof this.settings.restartBtn === 'string') ? this.quizEl.querySelector(this.settings.restartBtn) : this.settings.restartBtn;
    this.nextBtn = (typeof this.settings.nextBtn === 'string') ? this.quizEl.querySelector(this.settings.nextBtn) : this.settings.nextBtn;
    this.prevBtn = (typeof this.settings.prevBtn === 'string') ? this.quizEl.querySelector(this.settings.prevBtn) : this.settings.prevBtn;
    
    this.resultContent = (typeof this.settings.resultContent === 'string') ? this.quizEl.querySelector(this.settings.resultContent) : this.settings.resultContent;
    this.errorContent = (typeof this.settings.errorContent === 'string') ? this.quizEl.querySelector(this.settings.errorContent) : this.settings.errorContent;

    this.quizPanels = this.quizEl.querySelectorAll(this.settings.quizPanel);
    this.currentIndex = 0;
    
    this.boundOnStart = e => this.onStart(e);
    this.boundOnRestart = e => this.onRestart(e);
    this.boundOnNext = e => this.onNext(e);
    this.boundOnPrev = e => this.onPrev(e);
    this.boundOnRadioChange = e => this.onRadioChange(e);

    if (this.settings.autoInit) {
      this.init();
    }
  }

  init() {
    // add event listeners
    this.startBtn.addEventListener('click', this.boundOnStart, false);
    this.restartBtn.addEventListener('click', this.boundOnRestart, false);
    this.nextBtn.addEventListener('click', this.boundOnNext, false);
    this.prevBtn.addEventListener('click', this.boundOnPrev, false);
    Array.from(this.quizEl.querySelectorAll('input[type="radio"]')).forEach(radioEl => {
      radioEl.addEventListener('change', this.boundOnRadioChange, false);
    });
  }

  destroy() {
    // remove event listeners
    this.startBtn.removeEventListener('click', this.boundOnStart);
    this.restartBtn.removeEventListener('click', this.boundOnRestart);
    this.nextBtn.removeEventListener('click', this.boundOnNext);
    this.prevBtn.removeEventListener('click', this.boundOnPrev);
    Array.from(this.quizEl.querySelectorAll('input[type="radio"]')).forEach(radioEl => {
      radioEl.removeEventListener('change', this.boundOnRadioChange);
    });
  }

  onStart(e) {
    // switch welcome to questions
    this.panelWelcome.classList.add(this.settings.hiddenClassname);
    this.panelQuestions.classList.remove(this.settings.hiddenClassname);

    // show first panel
    this.showPanel(0);
  }

  onRestart(e) {
    // switch result to questions
    this.panelResult.classList.add(this.settings.hiddenClassname);
    this.panelQuestions.classList.remove(this.settings.hiddenClassname);

    // hide error, result
    this.resultContent.classList.add(this.settings.hiddenClassname);
    this.errorContent.classList.add(this.settings.hiddenClassname);

    // show first panel
    this.showPanel(0);
  }

  onNext(e) {
    const nextIndex = this.currentIndex + 1;
    if (typeof this.quizPanels[nextIndex] === 'undefined') {
      // submit
      this.submitQuiz();
      return;
    }
    this.currentIndex ++;
    this.showPanel();
  }

  onPrev(e) {
    const prevIndex = this.currentIndex - 1;
    if (prevIndex < 0) {
      return new Error('Quiz.onPrev() : Could not decrement panel index.');
    }
    this.currentIndex --;
    this.showPanel();
  }

  onRadioChange(e) {
    const radioEl = e.target;
    const panelEl = radioEl.closest(this.settings.quizPanel);
    if (this.isRadioGroupChecked(panelEl)) {
      this.nextBtn.disabled = false;
    } else {
      this.nextBtn.disabled = true;
    }
  }

  showPanel(index = null) {
    if (!index) {
      index = this.currentIndex;
    }
    const panelEl = this.quizPanels[index];
    Array.from(this.quizPanels).forEach(el => {
      if (parseInt(el.getAttribute(this.settings.panelNumAttribute)) === index) {
        el.classList.remove(this.settings.hiddenClassname);
        // return;
      } else {
        el.classList.add(this.settings.hiddenClassname);
      }
    });

    // check radio group state
    if (this.isRadioGroupChecked(panelEl)) {
      this.nextBtn.disabled = false;
    } else {
      this.nextBtn.disabled = true;
    }

    // show/hide prev button if needed
    if (index === 0) {
      this.prevBtn.classList.add(this.settings.hiddenClassname);
      this.prevBtn.disabled = true;
    } else {
      this.prevBtn.classList.remove(this.settings.hiddenClassname);
      this.prevBtn.disabled = false;
    }
  }

  submitQuiz() {
    if (typeof this.settings.submit === 'function') {
      const formEl = this.quizEl.querySelector('form');
      const buttonEls = [this.prevBtn, this.nextBtn];
      this.nextBtn.disabled = true;
      this.settings.submit.call(null, this, formEl, buttonEls);
    }
  }

  showResult(contentHtml) {
    // set result content
    this.resultContent.innerHTML = contentHtml;
    this.resultContent.classList.remove(this.settings.hiddenClassname);

    // switch panels:
    this.panelQuestions.classList.add(this.settings.hiddenClassname);
    this.panelResult.classList.remove(this.settings.hiddenClassname);

    // reset quiz:
    this.resetQuiz();
  }
  
  showError(errorHtml) {
    // set result content
    if (typeof errorHtml !== 'undefined' && errorHtml) {
      this.errorContent.innerHTML = errorHtml;
    }
    this.errorContent.classList.remove(this.settings.hiddenClassname);

    // switch panels:
    this.panelQuestions.classList.add(this.settings.hiddenClassname);
    this.panelResult.classList.remove(this.settings.hiddenClassname);

    // reset quiz:
    this.resetQuiz();
  }

  resetQuiz() {
    this.currentIndex = 0;
    Array.from(this.quizEl.querySelectorAll('input[type="radio"]')).forEach(radioEl => {
      radioEl.checked = false;
    });
    this.nextBtn.disabled = true;
    this.prevBtn.disabled = true;
    // this.prevBtn.classList.add(this.settings.hiddenClassname);
  }

  isRadioGroupChecked(panelEl) {
    let result = false;
    Array.from(panelEl.querySelectorAll('input[type="radio"]')).forEach(radioEl => {
      if (radioEl.checked) {
        result = true;
        return;
      }
    });
    return result;
  }

}

export default Quiz;
