import '../scss/style.scss';

import { maskPhone } from './inputmask';
import './ajaxvalidate';
import './cookie_consent';
import './quiz_demo';

window.addEventListener('DOMContentLoaded', () => {

  // Menubars
  const searchParams = new URLSearchParams(window.location.search);
  Array.from(document.querySelectorAll('[role="menubar"]')).forEach(menubarEl => {
    new CMS.Menu(menubarEl, {
      debug: searchParams.has('debug'),
    });
  });

  // Form validation
  $('[data-validate]').ajaxValidate();

  // Phone input mask
  maskPhone('input[name="phone"]')
    .catch(err => console.error(err));

  // Auto-responsive iframe embeds
  CMS.responsiveEmbed({
    selector: 'iframe',
  });
  
  // Auto-responsive tables
  CMS.responsiveTable({
    selector: '.table',
  });

});
