export function maskPhone(el) {
  const inputEls = (typeof el === 'string') ? document.querySelectorAll(el) : el;

  return new Promise((resolve, reject) => {
    if (typeof Cleave === 'undefined') {
      return reject('maskPhone() : could not find Cleave');
    }
    Array.from(inputEls).forEach(inputEl => {
      new Cleave(inputEl, {
        phone: true,
        phoneRegionCode: 'hu',
      });
      inputEl.addEventListener('focus', e => {
        if (inputEl.value === '') {
          inputEl.value = '+36 ';
        }
      }, false);
      inputEl.addEventListener('blur', e => {
        if (inputEl.value.trim() === '+36') {
          inputEl.value = '';
        }
      }, false);
    });
    resolve();
  });
}
