import ajax from './ajax';
import { Quiz } from 'cms-quiz';

window.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('[data-quiz]')) {
    Array.from(document.querySelectorAll('[data-quiz]')).forEach(quizEl => {
      new Quiz(quizEl, {
        submit: (self, formEl, buttonEls) => {

          const url = formEl.getAttribute('action');
          const method = formEl.getAttribute('method');
          const data = new FormData(formEl);
          
          setButtonsState(buttonEls, 'disabled');

          ajax({
            url,
            method,
            data,
          })
            .then(response => {
              const html = `
                <h6 class="text-center mb-4 fs-2">${response.data.result}${(parseInt(response.data.quiz_type) === 1) ? Settings.quiz.i18n.result_unit_percent : ' ' + Settings.quiz.i18n.result_unit_points}</h6>
                <div class="text-center mb-4">
                  ${response.data.text}
                </div>
              `;
              self.showResult(html);
            })
            .catch(err => {
              console.error(err);
              self.showError();
            })
            .then(() => {
              setButtonsState(buttonEls, 'enabled');
            }); 
        }
      });
    });
  }
});

function setButtonsState(buttonElements, state) {
  buttonElements.forEach(buttonEl => {
    if (state === 'enabled') {
      buttonEl.removeAttribute('disabled');
    } else if (state === 'disabled') {
      buttonEl.setAttribute('disabled', '');
    }
  });
}
