import { getTranslations } from './i18n';
import tplCookieConsentBar from './templates/cookie_consent_bar.pug';
import tplCookieConsentModal from './templates/cookie_consent_modal.pug';
import tplCookieConsentDrawer from './templates/cookie_consent_drawer.pug';

let cookie_consent_i18n = null; // cache translations

window.addEventListener('DOMContentLoaded', () => {

  // Itt adjuk meg a consent típusokat, és a hozzájuk tartozó cookie neveket
  // a kulcs fix, az érték bármi lehet (ez lesz a lerakott cookie neve)
  const consentTypes = {
    required: 'cc_required',
    stat: 'cc_stat',
    ads: 'cc_ads',
  };
  const cc_expire = 365; // ennyi napig élnek a lerakott cookie-k

  // tracker-ek automatikus beállítása oldalbetöltéskor
  updateTrackers(consentTypes);

  // consent típus checkbox CSS szelektor
  const checkboxSelector = 'input[name="consent_type[]"]';

  // const appearance = 2; // cookie bar
  // const appearance = 3; // blocker modal
  // const appearance = 4; // drawer
  const appearance = Settings.cookieConsentAppearance;

  let ccBarEl = null; // cookie bar HTML elem
  let ccModalEl = null; // bootstrap modal HTML elem
  let ccModal = null; // bootstrap modal instance
  let ccDrawerEl = null; // bootstrapoffcanvas HTML elem
  let ccDrawer = null; // bootstrap offcanvas instance

  // Cookie Consent instance létrehozás
  const cc = new CMS.CookieConsent({
    appearance,
    store: CMS.DataStore,
    consentTypes,
    storePrefix: 'gdpr/cookieconsent',
    toggle: 'a[href="#/suti-beallitasok"]', // cookie consent manuális megnyitása
    consentInput: checkboxSelector,
  });

  // Korábban elfogadott szintek beállítása cookie-k alapján
  for (const [key, cookieName] of Object.entries(consentTypes)) {
    if (CMS.Cookies.get(cookieName)) {
      cc.addConsent(key);
    }
  }

  // cookie sáv action: megnyitás vagy bezárás
  CMS.DataStore.subscribe('gdpr/cookieconsent/bar', ({ action }) => {
    // console.log('cookie bar action:', action);
    if (action === 'open') {
      getTranslations('cookie_consent', cookie_consent_i18n)
        .then(i18n => {
          cookie_consent_i18n = i18n;
          const ccBarHtml = tplCookieConsentBar({
            barId: 'cookie_bar',
            i18n,
          });
          document.body.insertAdjacentHTML('beforeend', ccBarHtml);
          ccBarEl = document.getElementById('cookie_bar');
          ccBarEl.classList.remove('d-none');
        })
        .catch(err => console.error(err));
    } else if (action === 'close') {
      ccBarEl.remove();
    }
  });

  // cookie modal action: megnyitás vagy bezárás
  CMS.DataStore.subscribe('gdpr/cookieconsent/modal', ({ action, acceptedTypes }) => {
    // console.log('cookie modal action:', action);
    if (action === 'open') {
      getTranslations('cookie_consent', cookie_consent_i18n)
        .then(i18n => {
          cookie_consent_i18n = i18n;
          if (!ccModal) {
            const cookieModalHtml = tplCookieConsentModal({
              modalId: 'cookie_modal',
              i18n,
            });
            document.body.insertAdjacentHTML('beforeend', cookieModalHtml);
            ccModalEl = document.getElementById('cookie_modal');
    
            // update info blocks on open
            updateInfoBlocks(acceptedTypes);
    
            // update checkboxes
            updateCheckboxes(acceptedTypes, {
              checkbox: checkboxSelector,
              parent: '#cookie_modal',
            });
    
            ccModal = new bootstrap.Modal(ccModalEl, {
              backdrop: 'static',
            });
            ccModal.show();
            
            ccModalEl.addEventListener('hidden.bs.modal', e => {
              ccModal._element.remove();
              ccModal._backdrop._element.remove();
              ccModal.dispose();
              ccModal = null;
            });
          }
        })
        .catch(err => console.error(err));
    } else if (action === 'close') {
      // hide modal
      ccModal.hide();
    }
  });

  // cookie drawer action: megnyitás vagy bezárás
  CMS.DataStore.subscribe('gdpr/cookieconsent/drawer', ({ action, acceptedTypes }) => {
    // console.log('cookie drawer action:', action);
    if (action === 'open') {
      getTranslations('cookie_consent', cookie_consent_i18n)
        .then(i18n => {
          cookie_consent_i18n = i18n;
          if (!ccDrawer) {
            const cookieDrawerHtml = tplCookieConsentDrawer({
              drawerId: 'cookie_drawer',
              i18n,
            });
            document.body.insertAdjacentHTML('beforeend', cookieDrawerHtml);
            ccDrawerEl = document.getElementById('cookie_drawer');
    
            // update checkboxes
            updateCheckboxes(acceptedTypes, {
              checkbox: checkboxSelector,
              parent: '#cookie_drawer',
            });
    
            ccDrawer = new bootstrap.Offcanvas(ccDrawerEl);
            ccDrawerEl.addEventListener('hidden.bs.offcanvas', e => {
              // manually set closed state
              cc.isDrawerOpened = false;
    
              // remove offcanvas from DOM:
              ccDrawer._backdrop._element.remove();
              ccDrawer._element.remove();
              ccDrawer = null;
            });
    
            // disable backdrop click hack (bootstrap 5.1):
            ccDrawerEl.addEventListener('shown.bs.offcanvas', e => {
              ccDrawer._backdrop._config.clickCallback = function() {};
            });
            ccDrawer.show();
          }
        })
        .catch(err => console.error(err));
    } else if (action === 'close') {
      ccDrawer.hide();
    }
  });

  // consent típusok dinamikus változása (checkboxok kattintgatásakor)
  CMS.DataStore.subscribe('gdpr/cookieconsent/consentTypes', (acceptedTypes) => {
    // console.log('consent types changed', acceptedTypes);
    // infó blokkok elrejtése/megjelenítése (modal-on belül van jelenleg használva)
    updateInfoBlocks(acceptedTypes);
  });
  
  // kiválasztott consent típusok elfogadása (ez fut le az összes elfogadó / elutasító gomb esetén is)
  CMS.DataStore.subscribe('gdpr/cookieconsent/accept', (acceptedTypes) => {
    // console.log('accept:', acceptedTypes);
    // save / delete cookies
    for (const [name, cookieName] of Object.entries(consentTypes)) {
      if (typeof acceptedTypes[name] === 'undefined' && CMS.Cookies.get(cookieName)) {
        CMS.Cookies.remove(cookieName);
      } else if (typeof acceptedTypes[name] !== 'undefined') {
        CMS.Cookies.set(cookieName, '1', {
          expires: cc_expire,
        });
      }
    }
    updateTrackers(consentTypes);
  });

  // cookie consent automatikus megnyitása (ha még nincs kötelező)
  if (!CMS.Cookies.get(consentTypes.required)) {
    cc.toggleMain();
  }

});

function updateCheckboxes(acceptedTypes, options = {}) {
  const settings = Object.assign({
    checkbox: 'input[name="consent_type[]"]',
    parent: '#cookie_modal',
  }, options);

  const parentEl = (typeof settings.parent === 'string') ? document.querySelector(settings.parent) : settings.parent;
  const cbs = (typeof settings.checkbox === 'string') ? Array.from(parentEl.querySelectorAll(settings.checkbox)) : settings.checkbox;

  cbs.forEach(cbEl => {
    if (typeof acceptedTypes[cbEl.value] !== 'undefined') {
      cbEl.checked = true;
    } else {
      cbEl.checked = false;
    }
  });
}

function updateInfoBlocks(acceptedTypes, options = {}) {
  const settings = Object.assign({
    attribute: 'data-cc-info-block',
  }, options);

  Array.from(document.querySelectorAll(`[${settings.attribute}]`)).forEach(infoBlockEl => {
    const name = infoBlockEl.getAttribute(settings.attribute);
    if (typeof acceptedTypes[name] !== 'undefined') {
      infoBlockEl.classList.remove('d-none');
    } else {
      infoBlockEl.classList.add('d-none');
    }
  });
}

function updateTrackers(consentTypes) {
  // gtag
  const gtagConsent = {
    'analytics_storage': (CMS.Cookies.get(consentTypes.stat)) ? 'granted' : 'denied',
    'ad_storage': (CMS.Cookies.get(consentTypes.ads)) ? 'granted' : 'denied',
  };
  // console.log('update gtag consent: ', gtagConsent);
  if (typeof gtag !== 'undefined') {
    gtag('consent', 'update', gtagConsent);
  }
  // fbq
  const fbqConsent = (CMS.Cookies.get(consentTypes.ads)) ? 'grant' : 'revoke';
  // console.log('update fbq consent: ', fbqConsent);
  if (typeof fbq !== 'undefined') {
    fbq('consent', fbqConsent);
  }
}
