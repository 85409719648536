var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (barId, i18n) {
      pug_html = pug_html + "\u003Cdiv" + (" class=\"position-fixed w-100 bottom-0 d-none\""+pug.attr("id", barId, true, true)) + "\u003E\u003Cdiv class=\"bg-dark py-3 border-top border-secondary shadow-lg text-light\"\u003E\u003Cdiv class=\"container\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-12 col-xl-5\"\u003E\u003Cp class=\"mb-3 mb-xl-0 d-xl-flex align-items-xl-center\"\u003E" + (null == (pug_interp = i18n.bar_content) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-12 col-xl-7 d-flex flex-column flex-xl-row justify-content-start align-items-center justify-content-xl-end\"\u003E\u003Cbutton class=\"btn btn-primary mb-2 mb-xl-0 px-2 px-md-3 order-first order-xl-last w-auto\" type=\"button\" data-cc-accept-all\u003E" + (pug.escape(null == (pug_interp = i18n.btn_accept_all) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003Cbutton class=\"btn btn-link mb-2 mb-xl-0 me-xl-2 order-last order-xl-first w-auto\" type=\"button\" data-cc-toggle-modal\u003E" + (pug.escape(null == (pug_interp = i18n.btn_settings) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "barId" in locals_for_with ?
        locals_for_with.barId :
        typeof barId !== 'undefined' ? barId : undefined, "i18n" in locals_for_with ?
        locals_for_with.i18n :
        typeof i18n !== 'undefined' ? i18n : undefined));
    ;;return pug_html;}

module.exports = template